import { Badge } from '@simplywallst/ui-core/next';
import type { ComponentProps } from 'react';

type Props = {
  userHoldsPosition: boolean | null | undefined;
  size?: ComponentProps<typeof Badge>['size'];
};

export const NarrativeDisclaimerBadge = ({
  userHoldsPosition,
  size = 'default',
}: Props) => {
  if (userHoldsPosition === true) {
    return (
      <Badge tone="good" size={size}>
        Invested
      </Badge>
    );
  }

  if (userHoldsPosition === false) {
    return (
      <Badge tone="bad" size={size}>
        Not Invested
      </Badge>
    );
  }

  return null;
};
